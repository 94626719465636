import React, { useState } from "react";

export interface ITooltip {
  children: React.ReactNode;
  delay?: number;
  direction?: string;
  content: any;
  tooltipClasses?: string;
  arrowClasses?: string;
}

const Tooltip: React.FC<ITooltip> = ({
  children,
  delay,
  direction = "top",
  content,
  tooltipClasses = "",
  arrowClasses = "",
}) => {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div
          className={`absolute z-10 p-2 rounded ${tooltipClasses} ${getTooltipPosition(
            direction
          )}`}
        >
          {content}
          <div className={`tooltip-arrow ${direction} ${arrowClasses}`}></div>
        </div>
      )}
      <style>
        {`
        .tooltip-arrow {
          width: 0;
          height: 0;
          border-style: solid;
          position: absolute;
        }
        .tooltip-arrow.top {
          border-width: 5px 5px 0;
          border-color: transparent transparent;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
        .tooltip-arrow.right {
          border-width: 5px 5px 5px 0;
          border-color: transparent transparent transparent white;
          left: -5px;
          top: 50%;
          transform: translateY(-50%);
        }
        .tooltip-arrow.bottom {
          border-width: 0 5px 5px;
          border-color: transparent transparent white transparent;
          top: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
        .tooltip-arrow.left {
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent white;
          right: -5px;
          top: 50%;
          transform: translateY(-50%);
        }

        /* Additional directions */
        .tooltip-arrow.top-left {
          border-width: 5px 5px 0;
          border-color: transparent transparent;
          bottom: -5px;
          left: 15%;
          transform: translateX(-15%);
        }
        .tooltip-arrow.top-center {
          border-width: 5px 5px 0;
          border-color: transparent transparent;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
        .tooltip-arrow.top-right {
          border-width: 5px 5px 0;
          border-color: transparent transparent;
          bottom: -5px;
          left: 85%;
          transform: translateX(-85%);
        }
        .tooltip-arrow.right-top {
          border-width: 5px 5px 5px 0;
          border-color: transparent transparent transparent white;
          left: -5px;
          top: 15%;
          transform: translateY(-15%);
        }
        .tooltip-arrow.right-center {
          border-width: 5px 5px 5px 0;
          border-color: transparent transparent transparent white;
          left: -5px;
          top: 50%;
          transform: translateY(-50%);
        }
        .tooltip-arrow.right-bottom {
          border-width: 5px 5px 5px 0;
          border-color: transparent transparent transparent white;
          left: -5px;
          top: 85%;
          transform: translateY(-85%);
        }
        .tooltip-arrow.bottom-left {
          border-width: 0 5px 5px;
          border-color: transparent transparent white transparent;
          top: -5px;
          left: 15%;
          transform: translateX(-15%);
        }
        .tooltip-arrow.bottom-center {
          border-width: 0 5px 5px;
          border-color: transparent transparent white transparent;
          top: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
        .tooltip-arrow.bottom-right {
          border-width: 0 5px 5px;
          border-color: transparent transparent white transparent;
          top: -5px;
          left: 85%;
          transform: translateX(-85%);
        }
        .tooltip-arrow.left-top {
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent white;
          right: -5px;
          top: 15%;
          transform: translateY(-15%);
        }
        .tooltip-arrow.left-center {
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent white;
          right: -5px;
          top: 50%;
          transform: translateY(-50%);
        }
        .tooltip-arrow.left-bottom {
          border-width: 5px 0 5px 5px;
          border-color: transparent transparent transparent white;
          right: -5px;
          top: 85%;
          transform: translateY(-85%);
        }
        `}
      </style>
    </div>
  );
};

const getTooltipPosition = (direction: string) => {
  switch (direction) {
    case "top":
      return "bottom-full mb-2 left-1/2 transform -translate-x-1/2";
    case "top-left":
      return "bottom-full mb-2 left-1/4 transform -translate-x-1/4";
    case "top-center":
      return "bottom-full mb-2 left-1/2 transform -translate-x-1/2";
    case "top-right":
      return "bottom-full mb-2 left-3/4 transform -translate-x-3/4";
    case "right":
      return "left-full ml-10 top-1/2 transform -translate-y-1/2";
    case "right-top":
      return "left-full ml-2 top-1/4 transform -translate-y-1/4";
    case "right-center":
      return "left-full ml-2 top-1/2 transform -translate-y-1/2";
    case "right-bottom":
      return "left-full ml-2 top-3/4 transform -translate-y-3/4";
    case "bottom":
      return "top-full mt-2 left-1/2 transform -translate-x-1/2";
    case "bottom-left":
      return "top-full mt-2 left-1/4 transform -translate-x-1/4";
    case "bottom-center":
      return "top-full mt-2 left-1/2 transform -translate-x-1/2";
    case "bottom-right":
      return "top-full mt-2 left-3/4 transform -translate-x-3/4";
    case "left":
      return "right-full mr-2 top-1/2 transform -translate-y-1/2";
    case "left-top":
      return "right-full mr-2 top-1/4 transform -translate-y-1/4";
    case "left-center":
      return "right-full mr-2 top-1/2 transform -translate-y-1/2";
    case "left-bottom":
      return "right-full mr-2 top-3/4 transform -translate-y-3/4";
    default:
      return "";
  }
};

export default Tooltip;
