import { useState, useEffect, useRef, useCallback } from "react";
import { axios } from "../utils/axios";

export interface RequestStateProps {
  loading: boolean;
  error: any | null;
  response: any;
}

export type RequestMethodTypes = "get" | "post" | "put" | "delete";

export const useRequest = (
  url: string,
  method: RequestMethodTypes,
  initReq: boolean = true
) => {
  const subscriptionRef = useRef(false);
  const [requestState, setRequestState] = useState<RequestStateProps>({
    loading: method === "get" && initReq ? true : false,
    error: null,
    response: null,
  });

  const handleRequest = useCallback(
    async (
      data?: object,
      successCallback?: (response: any) => void,
      errorCallback?: (error: any) => void,
      rewriteUrl?: string
    ) => {
      if (subscriptionRef.current) {
        setRequestState((current) =>
          !current.loading ? { ...current, loading: true } : current
        );
        try {
          const response = await axios[method](rewriteUrl || url, data);
          setRequestState((current) => ({
            error: null,
            response,
            loading: false,
          }));
          successCallback && successCallback(response);
        } catch (error: any) {
          setRequestState((current) => ({
            ...current,
            error,
            loading: false,
          }));
          errorCallback && errorCallback(error);
        }
      }
    },
    [url, method]
  );

  useEffect(() => {
    subscriptionRef.current = true;
    if (method === "get" && initReq) handleRequest();
    return () => {
      subscriptionRef.current = false;
    };
  }, [method, initReq, handleRequest]);

  return {
    requestState,
    handleRequest,
  };
};
