import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Onboarding from "../pages/onboarding";
import Navbar from "../components/navbar/Navbar";
import Health from "../pages/health";
import { PosProvider } from "../context/PosContext";
import PosRoutes from "./PosRoutes";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Toaster position="top-right" reverseOrder />
      <PosProvider>
        <Routes>
          <Route index path="/onboarding" element={<Onboarding />} />
          <Route path="/" element={<Onboarding />} />
          <Route path="/health" element={<Health />} />
          <Route path="/pos/*" element={<PosRoutes />} />
        </Routes>
      </PosProvider>
    </BrowserRouter>
  );
};

export default Router;
