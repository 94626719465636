import toast from "react-hot-toast";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PosContext } from "../../../context/PosContext";
import useCustomNavigate from "../../../components/pos/hooks/useCustomNavigate";
import Input from "../../../components/pos/ui/TaptopayInput";
import TaptoPayLogo from "../../../components/pos/ui/TaptoPayLogo";
import BackButton from "../../../components/pos/ui/BackButton";
import Button from "../../../components/pos/buttons/Button";

const SoftPosConfiguration = () => {
  const { updateSteps, softPosCreationData, updateSoftPosCreationData } =
    useContext(PosContext);

  const [terminalName, setTerminalName] = useState<string>(
    softPosCreationData.terminalName || ""
  );
  const [emailAddress, setEmailAddress] = useState<string>(
    softPosCreationData.emailAddress || ""
  );

  const navigate = useCustomNavigate();
  const { t } = useTranslation();

  const handleContinue = () => {
    if (
      !terminalName ||
      terminalName === "undefined" ||
      !emailAddress ||
      emailAddress === "undefined"
    ) {
      toast.error(`${t("terminals.softpos.toast.noCompleteConfigs")}`);
      return;
    }

    if (terminalName.length > 30) {
      toast.error(`${t("terminals.softpos.toast.invalidTerminalName")}`);
      return;
    }

    var emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegex.test(emailAddress)) {
      toast.error(`${t("terminals.softpos.toast.invalidEmail")}`);
      return;
    }

    updateSoftPosCreationData({
      terminalName: terminalName,
      emailAddress: emailAddress,
    });

    updateSteps({ softposconfiguration: true });
    navigate(`/pos/taptopay/confirmation`);
  };

  const handleTerminalNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTerminalName(event.target.value);
  };

  const handleEmailAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailAddress(event.target.value);
  };

  return (
    <div className="container mx-auto px-4 py-8 pt-4 w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <BackButton
        label={t("pos.buttons.back.name")}
        onClick={() => navigate("/pos/taptopay/location")}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold text-left relative">
            {t("terminals.softpos.configuration.title")}
          </h1>
          <h3 className="text-lg text-buckaroo-gray-800 font-semibold mb-8">
            {t("terminals.softpos.configuration.p1")}
          </h3>
          <div className="sm:w-1/2 w-full h-full space-y-6">
            <Input
              label={t(
                "terminals.softpos.configuration.placeholder.terminalName"
              )}
              name="terminalName"
              id="terminalName"
              type="text"
              value={terminalName}
              onChange={handleTerminalNameChange}
              className="w-72"
            />
            <Input
              label={t("terminals.softpos.configuration.placeholder.email")}
              name="emailAddress"
              id="emailAddress"
              type="email"
              value={emailAddress}
              onChange={handleEmailAddressChange}
              className="w-72"
            />
            <div className="flex justify-end">
              <Button
                label={t("pos.buttons.continue.name")}
                onClick={handleContinue}
                variant="primary"
                size="small"
              />
            </div>
          </div>
        </div>

        <div className="w-full h-full flex justify-center">
          <TaptoPayLogo />
        </div>
      </div>
    </div>
  );
};

export default SoftPosConfiguration;
