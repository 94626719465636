import React from "react";
import { Outlet } from "react-router-dom";
import getStepConfigs from "../../../components/pos/navbar/StepsConfig";
import { useTranslation } from "react-i18next";

const Stepper = React.lazy(
  () => import("../../../components/pos/navbar/Stepper")
);

const TapToPayFlow: React.FC = () => {
  const { t } = useTranslation();
  const { tapToPaySteps } = getStepConfigs(t);
  return (
    <>
      <Stepper stepsConfig={tapToPaySteps} />
      <Outlet />
    </>
  );
};

export default TapToPayFlow;
