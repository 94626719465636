import React from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getStepConfigs from "../../components/pos/navbar/StepsConfig";

const Stepper = React.lazy(() => import("../../components/pos/navbar/Stepper"));

const MigrationFlow: React.FC = () => {
  const { t } = useTranslation();
  const { migrationSteps } = getStepConfigs(t);
  return (
    <>
      <Stepper stepsConfig={migrationSteps} />
      <Outlet />
    </>
  );
};

export default MigrationFlow;
