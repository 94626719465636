import React from "react";
import Select, {
  Props as SelectProps,
  StylesConfig,
  components,
} from "react-select";
import { useController, Control } from "react-hook-form";
import ArrowIcon from "../../icons/ArrowIcon";

interface DropdownOption {
  value: string;
  label: string;
}

interface DropdownProps extends Omit<SelectProps<DropdownOption>, "options"> {
  name: string;
  control: Control<any>;
  label: string;
  options: DropdownOption[];
  placeholder?: string;
  isMulti?: boolean;
  required?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  name,
  control,
  label,
  options,
  placeholder = "Select an option",
  isMulti = false,
  required,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: required ? `${label} is required` : false },
  });

  const customStyles: StylesConfig<DropdownOption> = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#CDF564" : error ? "#EE3135" : "#9BA091",
      borderRadius: "9999px",
      backgroundColor: "#F4F0EC",
      minHeight: "40px",
      padding: "0 0.5rem",
      boxShadow: "none",
      outline: "none",
      "&:hover": {
        borderColor: state.isFocused ? "#CDF564" : "#9BA091",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#9BA091",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F0EC",
      borderRadius: "12px",
      overflow: "hidden",
      marginTop: "8px",
      padding: "0.2rem",
      border: "1px solid #CDF564",
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: "12px",
      color: state.isSelected ? "#163255" : "#000000",
      backgroundColor: state.isSelected
        ? "#B7ED44"
        : state.isFocused
        ? "#B7ED44"
        : "#F4F0EC",
      padding: "8px 8px",
      marginTop: "4px",
      outline: "none",
      "&:hover": {
        backgroundColor: "#CFF355",
        color: "#163255",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#CDF564",
      color: "#ffffff",
      borderRadius: "9999px",
      padding: "2px 6px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#ffffff",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#CFF355",
        color: "#ffffff",
      },
    }),
  };

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <span
        className={`transform transition-transform duration-200 ${
          props.selectProps.menuIsOpen ? "" : "rotate-180"
        }`}
      >
        <ArrowIcon />
      </span>
    </components.DropdownIndicator>
  );

  return (
    <div className="relative mb-3">
      <label className="text-sm text-black mb-1">{label}</label>
      <Select
        {...field}
        id={name}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        isMulti={isMulti}
        classNamePrefix="react-select"
        // components={{ DropdownIndicator }}
        onChange={(value) => {
          field.onChange(
            isMulti
              ? (value as DropdownOption[]).map((v) => v.value)
              : (value as DropdownOption).value
          );
        }}
        value={
          isMulti
            ? options.filter((option) =>
                (field.value || []).includes(option.value)
              )
            : options.find((option) => option.value === field.value) || null
        }
      />
      {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
    </div>
  );
};

export default Dropdown;
