import { useEffect, useRef } from "react";

interface LocationModalProps {
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  className?: string;
  showCloseIcon?: boolean;
  isOpen: boolean;
}

const LocationModal: React.FC<LocationModalProps> = ({
  onClose,
  title,
  children,
  className = "",
  showCloseIcon = true,
  isOpen,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") onClose();
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex justify-center items-center p-4 bg-gray-800 bg-opacity-30`}
    >
      <div
        ref={modalRef}
        className={`bg-white p-4 rounded-md shadow-lg relative max-w-lg w-full ${className}`}
        role="dialog"
        aria-modal="true"
        aria-labelledby={title ? "modalTitle" : undefined}
      >
        {showCloseIcon && (
          <button
            onClick={onClose}
            className="absolute top-1 right-2 text-lg text-gray-600 hover:text-gray-800"
            aria-label="Close"
          >
            &times;
          </button>
        )}
        {title && (
          <h3 id="modalTitle" className="text-lg font-semibold mb-4">
            {title}
          </h3>
        )}
        {children}
      </div>
    </div>
  );
};

export default LocationModal;
