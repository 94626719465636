import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomNavigate from "../../../components/pos/hooks/useCustomNavigate";
import { PosContext } from "../../../context/PosContext";
import { useDetermineOrderType } from "../../../components/pos/hooks/useDetermineOrderType";
import toast from "react-hot-toast";
import Modal from "../../../components/pos/ui/Modal";
import { formatPrice } from "../../../components/pos/utilities/formatPrice";
import Button from "../../../components/pos/buttons/Button";

const SoftPosConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { orders, softPosCreationData, updateOrderType } =
    useContext(PosContext);
  const orderType = useDetermineOrderType();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleConfirmRemove = () => {
    setIsModalOpen(false);
  };

  const handleFinish = () => {
    if (softPosCreationData.location && softPosCreationData?.location) {
      updateOrderType(orderType);
      navigate(`/onboarding`);
    } else {
      toast.error(
        t(
          `${t("errors.toastError.migrate.title")} : ${
            !softPosCreationData.location
              ? t("errors.toastError.migrate.location")
              : ""
          }`
        )
      );
    }
  };

  if (!orders || orders.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center my-10">
        <div className="text-lg text-gray-700 mb-4">
          {t("pos.review.noOrder")}
        </div>
        <button
          onClick={() => navigate(`/pos/terminal`)}
          className="bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded"
        >
          {t("pos.buttons.goBack")}{" "}
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <h1 className="text-2xl font-bold text-left relative">
        {t("terminals.softpos.confirmation.title")}
      </h1>
      <h3 className="text-lg text-buckaroo-gray-800 font-semibold mb-8">
        {t("terminals.softpos.confirmation.p1")}
      </h3>
      <div className="border-b my-4">
        <>
          <div className="flex flex-col md:grid md:grid-cols-12 px-4 pt-4 ">
            <div className="md:col-span-4">
              <div className="flex items-center justify-between mb-1">
                <h2 className="text-xl font-bold text-gray-800">
                  {softPosCreationData.terminalName}
                </h2>
              </div>
              <div className="text-md">{softPosCreationData.emailAddress}</div>
              <div className="text-md">
                <div className="flex">
                  <span className="mr-2">Price: </span>
                  <span>
                    1,7% {t("terminals.softpos.confirmation.priceValue")}
                  </span>
                </div>
              </div>
              <span className="text-md text-gray-700"></span>
              {t("pos.review.location")}{" "}
              {softPosCreationData.location &&
                softPosCreationData.location.locationName}
            </div>

            <div className="md:col-span-3 flex justify-end md:justify-center items-center">
              {isModalOpen && (
                <Modal
                  onClose={() => setIsModalOpen(false)}
                  title={`${t("pos.accessories.delete.remove")}`}
                >
                  <p className="text-lg text-gray-800 mb-4"></p>

                  <div className="flex mr-2 mb-2 justify-end space-x-3">
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-900 font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-gray-300"
                    >
                      {t("pos.accessories.delete.cancelBtn")}
                    </button>
                    <button
                      onClick={handleConfirmRemove}
                      className="bg-red-200 hover:bg-red-300 text-red-800 hover:text-red-900 font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-red-300"
                    >
                      {t("pos.accessories.delete.removeBtn")}
                    </button>
                  </div>
                </Modal>
              )}
            </div>

            <div className="md:col-span-3  flex justify-start items-center"></div>
          </div>
          <div className="flex flex-col md:grid md:grid-cols-12 px-4 pb-4 ">
            <div className="text-lg font-semibold text-gray-700 col-span-3">
              {t("pos.review.fee")}
            </div>
            <div className="col-span-6"></div>
            <div className="text-lg font-semibold text-gray-700 col-span-1 flex justify-start">
              €{formatPrice(29)}
            </div>
          </div>
        </>
      </div>
      <div className="mt-8 mb-4">
        <p>{t("terminals.softpos.confirmation.info")}</p>
      </div>
      <div className="flex flex-col p-4 md:flex-row justify-between mt-5"></div>
      <div className="flex justify-end space-x-4 mt-8">
        <Button
          label={t("terminals.softpos.buttons.name")}
          onClick={() => handleFinish()}
          variant="primary"
          size="small"
        />
      </div>
    </div>
  );
};

export default SoftPosConfirmation;
