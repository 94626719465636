import React from "react";

interface BackButtonProps {
  label: string;
  onClick: () => void;
  className?: string;
}

const BackButton: React.FC<BackButtonProps> = ({
  label,
  onClick,
  className,
}) => {
  return (
    <div
      className={`group flex items-center w-fit text-buckaroo-blue-500 cursor-pointer mb-6 transition-all duration-300 ease-in-out transform ${className}`}
      onClick={onClick}
    >
      <svg
        className="w-6 h-6 mr-1 transition-transform duration-300 ease-in-out group-hover:-translate-x-1 group-hover:scale-110"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"
        ></path>
      </svg>
      <span className="text-buckaroo-blue-500 font-semibold group-hover:text-opacity-80">
        {label}
      </span>
    </div>
  );
};

export default BackButton;
