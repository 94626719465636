import React, { useContext } from "react";
import { PosContext } from "../../context/PosContext";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import { t } from "i18next";
import MapView from "../../components/pos/ui/MapView";
import { formatPrice } from "../../components/pos/utilities/formatPrice";
import { useDetermineOrderType } from "../../components/pos/hooks/useDetermineOrderType";
import toast from "react-hot-toast";
import OrderReviewBundle from "../../components/pos/orderReview/OrderReviewBundle";
import Button from "../../components/pos/buttons/Button";
import BackButton from "../../components/pos/ui/BackButton";

const MigrateReview: React.FC = () => {
  const navigate = useCustomNavigate();
  const { migrate, terminals, updateOrderType } = useContext(PosContext);
  const orderType = useDetermineOrderType();
  const transactiedienstTerminal = terminals.find((terminal) =>
    terminal.ProductName.includes("SEPAY Transactiedienst")
  );

  const handleFinish = () => {
    if (migrate.location && migrate.TIDs?.length > 0) {
      updateOrderType(orderType);
      navigate("/onboarding");
    } else {
      toast.error(
        t(
          `${t("errors.toastError.migrate.title")} : ${
            !migrate.location ? t("errors.toastError.migrate.location") : ""
          } ${!migrate.TIDs?.length ? t("errors.toastError.migrate.tid") : ""}`
        )
      );
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <BackButton
        label={t("pos.buttons.back.name")}
        onClick={() => navigate("/pos/migrate/terminal")}
      />
      <h1 className="text-3xl font-bold text-left">
        {t("pos.buttons.review.title")}
      </h1>
      <h3 className="text-lg text-buckaroo-gray-800 font-semibold mb-8">
        {t("pos.buttons.review.activate")}
      </h3>
      <div className="border-b mb-5 pb-3">
        <h2 className="text-lg font-semibold text-gray-800">TID(s) :</h2>
        <ul className="flex flex-col items-start">
          {migrate.TIDs.map((tid, index) => (
            <li
              key={index}
              className="flex space-x-10 px-3 py-1 ml-5 transition-colors font-normal"
            >
              <span></span>
              {tid}
              <span>
                <span>€3,35 / {t("pos.purchaseTotal.month")}</span>
              </span>
              <span>
                €
                {formatPrice(
                  transactiedienstTerminal?.Subscriptions[0].Fees.find(
                    (fee) => fee.Type === "OneTimeFee"
                  )?.Price ?? 0
                )}{" "}
                {t("pos.buttons.review.fee")}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="border-b w-full grid grid-cols-1 md:grid-cols-10">
        <OrderReviewBundle />
      </div>
      <div className="text-lg font-semibold text-gray-800 mt-4">
        {t("pos.review.location")}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {migrate.location && (
          <div
            className={`flex flex-col justify-between p-2 mt-2 border ml-12 sm:ml-0 border-gray-200 rounded-xl shadow transition duration-300 transform hover:shadow-lg w-72`}
          >
            <MapView
              street={migrate.location.street}
              houseNumber={migrate.location.houseNumber}
              addition={migrate.location.addition}
              postalCode={migrate.location.postalCode}
              city={migrate.location.city}
              country={migrate.location.country}
              className="mb-4 border flex justify-center"
              imgClassName="rounded-lg"
              zoom={17}
            />
            <div className="space-y-1 mb-2 px-3">
              <h3 className="text-xl font-semibold text-gray-800">
                {migrate.location.locationName}
              </h3>
              <p className="text-sm text-gray-600">
                {migrate.location.street}, {migrate.location.houseNumber}{" "}
                {migrate.location.addition}
              </p>
              <p className="text-sm text-gray-600">
                {migrate.location.postalCode}, {migrate.location.city},{" "}
                {migrate.location.country}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="mt-8 mb-4">
        <p>{t("pos.review.migrate.info")}</p>
      </div>
      <div className="flex justify-end">
        <Button
          label={t("pos.buttons.migrate")}
          onClick={() => handleFinish()}
          variant="primary"
          size="small"
        />
      </div>
    </div>
  );
};

export default MigrateReview;
