import { SoftPosCreationType } from "../interfaces/IPos";

const CountryOptions = [
  { value: "Netherlands", code: "NL" },
  { value: "Germany", code: "DE" },
  { value: "Belgium", code: "BE" },
];

export const transformTapToPayPayload = (tapToPay: SoftPosCreationType) => {
  if (!tapToPay.location) {
    return {
      orderLinesTapToPay: [],
      locationsTapToPay: [],
      deliveryAddressTapToPay: {},
    };
  }

  const getCountryCodeOrValue = (countryName: string) => {
    const countryOption = CountryOptions.find(
      (option) => option.value === countryName
    );
    return countryOption ? countryOption.code : countryName;
  };

  const locationId = tapToPay.location.id.toString();

  const orderLinesTapToPay = [
    {
      locationId: locationId,
      softPosService: {
        activationEmailAddress: tapToPay.emailAddress,
        terminalName: tapToPay.terminalName,
        contractFee: 29,
      },
    },
  ];

  const locationAddress = {
    street: tapToPay.location.street,
    houseNumber: tapToPay.location.houseNumber,
    houseNumberExtension: tapToPay.location.addition || "",
    additionalAddressLine: tapToPay.location.addition || "",
    postalCode: tapToPay.location.postalCode,
    city: tapToPay.location.city,
    countryCode: getCountryCodeOrValue(tapToPay.location.country || ""),
  };

  const locationsTapToPay = [
    {
      locationId,
      locationName: tapToPay.location.locationName || "",
      locationAddress: locationAddress,
    },
  ];

  const deliveryAddressTapToPay = { ...locationAddress };

  return {
    orderLinesTapToPay,
    locationsTapToPay,
    deliveryAddressTapToPay,
  };
};
