import { useState } from "react";
import OnboardingForm from "../../components/forms/OnboardingForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Success from "../success";
import { OverlayStatus } from "../../enums/OverlayStaus";

const Onboarding = () => {
  const [overlayStatus, setOverlayStatus] = useState<OverlayStatus>(
    OverlayStatus.None
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");

  if (overlayStatus === OverlayStatus.None && isFormSubmitted) {
    return <Success firstName={firstName} companyName={companyName} />;
  }

  return (
    <div className="flex flex-col md:flex-row h-[calc(100vh-70px)] w-full bg-white">
      <div className="w-full md:w-1/2 flex justify-center px-6 md:px-0 mt-12 lg:mt-16">
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
          language="en"
        >
          <OnboardingForm
            setOverlayStatus={setOverlayStatus}
            overlayStatus={overlayStatus}
            setIsFormSubmitted={setIsFormSubmitted}
            setFirstName={setFirstName}
            setCompanyName={setCompanyName}
          />
        </GoogleReCaptchaProvider>
      </div>
      <div
        className="w-full md:w-1/2 min-h-[50vh] lg:min-h-screen flex items-center bg-cover bg-center relative mt-10 md:mt-0"
        style={{
          backgroundImage: `url(${require("../../images/jpg/sidebar-pic-1.jpg")})`,
          backgroundPosition: "center right",
          backgroundSize: "cover",
        }}
      >
        <div className="hidden md:block w-full h-full bg-gray-800 bg-opacity-20"></div>
      </div>
    </div>
  );
};

export default Onboarding;
