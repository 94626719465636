import { useNavigate, useLocation } from "react-router-dom";

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigatePreservingQuery = (path: string) => {
    const search = location.search;
    navigate(`${path}${search}`);
  };

  return navigatePreservingQuery;
};

export default useCustomNavigate;
