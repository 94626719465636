import React from "react";
import { Controller } from "react-hook-form";

interface InputProps {
  label: string;
  name: string;
  id: string;
  type: string;
  placeholder?: string;
  className?: string;
  error?: string;
  control: any;
  disabled?: boolean;
  value?: any;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      name,
      id,
      type,
      value,
      placeholder,
      className,
      error,
      control,
      disabled,
    },
    ref
  ) => {
    return (
      <div className={`w-full ${className}`}>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <input
              id={id}
              type={type}
              {...field}
              value={value}
              ref={ref}
              placeholder={placeholder}
              disabled={disabled}
              style={{
                border: `1px solid ${error ? "#EE3135" : "#9BA091"}`,
                borderRadius: "9999px",
                backgroundColor: disabled ? "#F4F0EC" : "#F4F0EC",
                color: "#000000",
                padding: "0.5rem 1rem",
                width: "100%",
                outline: "none",
                transition: "border-color 0.3s ease",
              }}
              onFocus={(e) => {
                if (!disabled) e.target.style.borderColor = "#CDF564";
              }}
              onBlur={(e) => {
                if (!disabled)
                  e.target.style.borderColor = error ? "#EE3135" : "#9BA091";
              }}
            />
          )}
        />
        {error && <span className="text-red-500 text-xs mt-1">{error}</span>}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
