import { t } from "i18next";
import React from "react";
import { formatPrice } from "../utilities/formatPrice";

interface BundleCardProps {
  range: number;
  pricePerMonth: number;
  selected: boolean;
  transactionPrice: number;
  onSelect: () => void;
}

const BundleCard: React.FC<BundleCardProps> = ({
  range,
  pricePerMonth,
  selected,
  transactionPrice,
  onSelect,
}) => {
  return (
    <div
      className={` p-4 rounded-2xl m-2 shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer ${
        selected ? "bg-buckaroo-gray-100" : "border bg-white border-gray-300"
      }`}
      onClick={onSelect}
    >
      <div className=" text-gray-600 text-center">
        <h2 className="text-xl font-bold text-gray-600">
          {`${range === 0 ? `${t("pos.bundle.noBundle")}` : range}`}{" "}
          {t("pos.bundle.transactions")}
        </h2>
        {range !== 0 && (
          <p className="text-sm sm:text-md font-semibold text-gray-600 mb-1">
            {t("pos.bundle.price")}: €{formatPrice(pricePerMonth)} /
            {t("pos.review.bundle.perMonth")}
          </p>
        )}
        {range === 0 && (
          <p className=" text-sm sm:text-md font-semibold text-gray-600 mb-1">
            {transactionPrice
              ? `${t("pos.bundle.transaction")}: €${formatPrice(
                  transactionPrice
                )}`
              : ""}
          </p>
        )}
      </div>
    </div>
  );
};

export default BundleCard;
