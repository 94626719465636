import React, { createContext, useState, ReactNode, useEffect } from "react";
import { IBundleTransaction } from "../interfaces/IBundle";
import { bundleTransactions } from "../data/bundleData";
import {
  IOrder,
  ISelectedAccessory,
  ISteps,
  LocationType,
  MigrateType,
  OrderType,
  SoftPosCreationType,
} from "../interfaces/IPos";
import { IAccessory } from "../interfaces/IAccessory";
import { IProduct } from "../interfaces/ITerminal";
import { posData } from "../data/posData";
import { yellowPosData } from "../data/POSYellowData";
import { useQueryParameters } from "../utils/useQueryParameters";

interface PosContextState {
  terminals: IProduct[];
  accessories: IAccessory[];
  orders: IOrder[];
  activeOrderId: number | null;
  selectedBundle: IBundleTransaction;
  setActiveOrder: (orderId: number) => void;
  setSelectedBundle: (bundle: IBundleTransaction) => void;
  steps: ISteps;
  updateOrder: (updatedOrder: Partial<IOrder>) => void;
  updateSteps: (updatedSteps: Partial<ISteps>) => void;
  addNewOrder: () => void;
  removeOrder: (orderId: number) => void;
  updateTerminalQuantity: (orderId: number, quantityChange: number) => void;
  updateQuantity: (
    itemType: "terminal" | "accessory",
    itemId: string,
    quantityChange: number,
    orderId?: number
  ) => void;
  removeTerminal: (orderId: number) => void;
  aggregatedAccessories: ISelectedAccessory[];
  updateAggregatedAccessories: (
    selectedAccessories: ISelectedAccessory[]
  ) => void;
  handleAccessoryQuantityChange: (
    invoiceCode: string,
    quantityChange: number
  ) => void;
  removeAccessory: (invoiceCode: string) => void;
  migrate: MigrateType;
  updateMigrate: (updatedMigrate: Partial<MigrateType>) => void;
  deliveryAddress: LocationType;
  updateDeliveryAddress: (updatedMigrate: Partial<LocationType>) => void;
  orderType?: OrderType;
  updateOrderType: (updatedOrderType: Partial<OrderType>) => void;
  softPosCreationData: SoftPosCreationType;
  updateSoftPosCreationData: (
    softPosData: Partial<SoftPosCreationType>
  ) => void;
  merchantId: string | null;
  partner: string | null;
}

const initialOrder: IOrder = {
  orderId: 1,
  selectedTerminal: null,
};

const initialPosState: PosContextState = {
  terminals: posData.Webshop.Products,
  accessories: posData.Webshop.Accessories,
  orders: [initialOrder],
  steps: {
    terminal: false,
    subscription: false,
    bundle: false,
    accessories: false,
    review: false,
    migratelocation: false,
    migrateterminal: false,
    migratebundle: false,
    migratereview: false,
    softposlocation: false,
    softposconfiguration: false,
    softposconfirmation: false,
  },
  activeOrderId: 1,
  selectedBundle: bundleTransactions[2],
  setActiveOrder: () => {},
  setSelectedBundle: () => {},
  updateOrder: (updatedOrder: Partial<IOrder>) => {},
  updateSteps: (updatedSteps: Partial<ISteps>) => {},
  addNewOrder: () => {},
  removeOrder: () => {},
  updateTerminalQuantity: () => {},
  updateQuantity: () => {},
  removeTerminal: () => {},
  aggregatedAccessories: [],
  updateAggregatedAccessories: () => {},
  handleAccessoryQuantityChange: () => {},
  removeAccessory: () => {},
  migrate: {
    location: null,
    TIDs: [],
    selectedBundle: bundleTransactions[2],
  },
  updateMigrate: () => {},
  deliveryAddress: {
    id: 1,
    locationName: "",
    street: "",
    houseNumber: "",
    addition: "",
    postalCode: "",
    city: "",
    country: "",
    isSelected: false,
  },
  updateDeliveryAddress: () => {},
  updateOrderType: (updatedOrderType: Partial<OrderType>) => {},
  softPosCreationData: {
    location: null,
    terminalName: null,
    emailAddress: null,
  },
  updateSoftPosCreationData: () => {},
  merchantId: null,
  partner: null,
};

export const PosContext = createContext<PosContextState>(initialPosState);

interface PosProviderProps {
  children: ReactNode;
}

const getPricingDataForPartner = (partner: string | null) => {
  const isYellowPartner =
    partner ===
    "eb653c06e00ff430289f6ae7b3f899b60f2b0e02f2f082f646ce9f2c99b212ba";
  return isYellowPartner ? yellowPosData : posData;
};

export const PosProvider: React.FC<PosProviderProps> = ({ children }) => {
  const queryParams = useQueryParameters();
  const currentPartner = queryParams.partner || null;

  const [state, setState] = useState<PosContextState>(() => {
    const storedState = sessionStorage.getItem("posState");
    const parsedState = storedState ? JSON.parse(storedState) : null;

    if (parsedState && parsedState.partner === currentPartner) {
      return parsedState;
    }

    return {
      ...initialPosState,
      terminals: getPricingDataForPartner(currentPartner).Webshop.Products,
      accessories: getPricingDataForPartner(currentPartner).Webshop.Accessories,
      partner: currentPartner,
    };
  });

  const persistState = (newState: PosContextState) => {
    sessionStorage.setItem("posState", JSON.stringify(newState));
  };

  useEffect(() => {
    persistState(state);
  }, [state]);

  useEffect(() => {
    if (state.partner !== currentPartner) {
      const newState = {
        ...initialPosState,
        terminals: getPricingDataForPartner(currentPartner).Webshop.Products,
        accessories:
          getPricingDataForPartner(currentPartner).Webshop.Accessories,
        partner: currentPartner,
      };
      setState(newState);
      persistState(newState);
    }
  }, [currentPartner, state.partner]);

  const setSelectedBundle = (bundle: IBundleTransaction) => {
    setState((prevState) => ({
      ...prevState,
      selectedBundle: bundle,
    }));
  };

  const updateOrderType = (updatedOrderType: Partial<OrderType>) => {
    setState((prevState) => {
      const currentOrderType = prevState.orderType || {
        flow: "POS",
        type: "PosOnly",
      };

      const newOrderType = {
        flow: updatedOrderType.flow ?? currentOrderType.flow,
        type: updatedOrderType.type ?? currentOrderType.type,
      };

      return {
        ...prevState,
        orderType: newOrderType,
      };
    });
  };

  const updateAggregatedAccessories = (
    selectedAccessories: ISelectedAccessory[]
  ) => {
    setState((prevState) => {
      const newAggregated: { [invoiceCode: string]: ISelectedAccessory } = {};

      prevState.aggregatedAccessories.forEach((acc) => {
        newAggregated[acc.accessory.InvoiceCode] = { ...acc };
      });

      selectedAccessories.forEach((acc) => {
        if (acc.quantity > 0) {
          newAggregated[acc.accessory.InvoiceCode] = { ...acc };
        }
      });

      Object.keys(newAggregated).forEach((invoiceCode) => {
        if (newAggregated[invoiceCode].quantity <= 0) {
          delete newAggregated[invoiceCode];
        }
      });

      return {
        ...prevState,
        aggregatedAccessories: Object.values(newAggregated),
      };
    });
  };

  const handleAccessoryQuantityChange = (
    invoiceCode: string,
    quantityChange: number
  ) => {
    setState((prevState) => ({
      ...prevState,
      aggregatedAccessories: prevState.aggregatedAccessories.map((acc) =>
        acc.accessory.InvoiceCode === invoiceCode
          ? { ...acc, quantity: acc.quantity + quantityChange }
          : acc
      ),
    }));
  };

  const removeAccessory = (invoiceCode: string) => {
    setState((prevState) => ({
      ...prevState,
      aggregatedAccessories: prevState.aggregatedAccessories.filter(
        (acc) => acc.accessory.InvoiceCode !== invoiceCode
      ),
    }));
  };

  const updateOrder = (updatedOrder: Partial<IOrder>) => {
    setState((prevState) => {
      const updatedOrders = prevState.orders.map((order) => {
        if (order.orderId === prevState.activeOrderId) {
          // Update selected terminal if provided
          let updatedOrderData = { ...order };
          if (updatedOrder.selectedTerminal) {
            const isTerminalChanged =
              order.selectedTerminal?.InvoiceCode !==
              updatedOrder.selectedTerminal?.InvoiceCode;

            updatedOrderData = {
              ...updatedOrderData,
              selectedTerminal: {
                ...order.selectedTerminal,
                ...updatedOrder.selectedTerminal,
                quantity: isTerminalChanged
                  ? 1
                  : updatedOrder.selectedTerminal.quantity ||
                    order.selectedTerminal?.quantity ||
                    1,
              },
              selectedAccessories: isTerminalChanged
                ? []
                : order.selectedAccessories, // Clear accessories if terminal changes
            };
          }

          // Update selected subscription if provided
          if (updatedOrder.selectedSubscription) {
            updatedOrderData = {
              ...updatedOrderData,
              selectedSubscription: updatedOrder.selectedSubscription,
            };
          }

          // Update selected accessories if provided
          if (updatedOrder.selectedAccessories) {
            updatedOrderData = {
              ...updatedOrderData,
              selectedAccessories: updatedOrder.selectedAccessories,
            };
          }

          // Update location if provided
          if (updatedOrder.location) {
            updatedOrderData = {
              ...updatedOrderData,
              location: updatedOrder.location,
            };
          }

          //
          return updatedOrderData;
        }
        return order;
      });

      return {
        ...prevState,
        orders: updatedOrders,
      };
    });
  };

  const updateMigrate = (updatedMigrate: Partial<MigrateType>) => {
    setState((prevState) => ({
      ...prevState,
      migrate: {
        ...prevState.migrate,
        ...updatedMigrate,
      },
    }));
  };

  const updateDeliveryAddress = (
    updatedDeliveryAddress: Partial<LocationType>
  ) => {
    setState((prevState) => ({
      ...prevState,
      deliveryAddress: {
        ...prevState.deliveryAddress,
        ...updatedDeliveryAddress,
      },
    }));
  };

  const updateSteps = (updatedSteps: Partial<ISteps>) => {
    setState((prevState) => ({
      ...prevState,
      steps: { ...prevState.steps, ...updatedSteps },
    }));
  };

  const addNewOrder = () => {
    const newOrderId = Math.floor(Math.random() * 1001);
    const newOrder: IOrder = {
      orderId: newOrderId,
      selectedTerminal: null,
    };

    setState((prevState) => ({
      ...prevState,
      orders: [...prevState.orders, newOrder],
      activeOrderId: newOrderId,
      steps: {
        terminal: false,
        subscription: false,
        bundle: false,
        accessories: false,
        review: false,
        migratelocation: false,
        migrateterminal: false,
        migratebundle: false,
        migratereview: false,
        softposlocation: false,
        softposconfiguration: false,
        softposconfirmation: false,
      },
    }));
  };

  const removeOrder = (orderId: number) => {
    setState((prevState) => {
      const filteredOrders = prevState.orders.filter(
        (order) => order.orderId !== orderId
      );

      return {
        ...prevState,
        orders: filteredOrders,
        activeOrderId: filteredOrders.length ? filteredOrders[0].orderId : null,
      };
    });
  };

  const setActiveOrder = (orderId: number) => {
    setState((prevState) => ({
      ...prevState,
      activeOrderId: orderId,
    }));
  };

  const updateTerminalQuantity = (orderId: number, quantityChange: number) => {
    setState((prevState) => {
      const updatedOrders = prevState.orders.map((order) => {
        if (order.orderId === orderId && order.selectedTerminal) {
          return {
            ...order,
            selectedTerminal: {
              ...order.selectedTerminal,
              quantity: order?.selectedTerminal?.quantity || 0 + quantityChange,
            },
          };
        }
        return order;
      });

      return { ...prevState, orders: updatedOrders };
    });
  };

  const removeTerminal = (orderId: number) => {
    setState((prevState) => {
      const updatedOrders = prevState.orders.map((order) => {
        if (order.orderId === orderId) {
          // Set selectedTerminal to null, keeping the rest of the order intact
          return { ...order, selectedTerminal: null };
        }
        return order;
      });

      return { ...prevState, orders: updatedOrders };
    });
  };

  const updateQuantity = (
    itemType: "terminal" | "accessory",
    itemId: string,
    quantityChange: number,
    orderId?: number
  ) => {
    setState((prevState) => {
      const updatedOrders = prevState.orders.map((order) => {
        if (order.orderId === orderId) {
          if (
            itemType === "terminal" &&
            order.selectedTerminal?.InvoiceCode === itemId
          ) {
            // Update terminal quantity
            const updatedTerminal = {
              ...order.selectedTerminal,
              quantity: Math.max(
                (order.selectedTerminal.quantity || 0) + quantityChange,
                0
              ),
            };
            return { ...order, selectedTerminal: updatedTerminal };
          } else if (itemType === "accessory") {
            // Update accessory quantity within the specific order
            const updatedAccessories = order.selectedAccessories?.map((acc) => {
              if (acc.accessory.InvoiceCode === itemId) {
                return {
                  ...acc,
                  quantity: Math.max((acc.quantity || 0) + quantityChange, 0),
                };
              }
              return acc;
            });
            return { ...order, selectedAccessories: updatedAccessories };
          }
        }
        return order;
      });

      return { ...prevState, orders: updatedOrders };
    });
  };

  const updateSoftPosCreationData = (
    softPosCreationData: Partial<SoftPosCreationType>
  ) => {
    setState((prevState) => ({
      ...prevState,
      softPosCreationData: {
        ...prevState.softPosCreationData,
        ...softPosCreationData,
      },
    }));
  };

  return (
    <PosContext.Provider
      value={{
        ...state,
        updateOrder,
        updateSteps,
        addNewOrder,
        setActiveOrder,
        removeOrder,
        updateTerminalQuantity,
        updateQuantity,
        removeTerminal,
        updateAggregatedAccessories,
        handleAccessoryQuantityChange,
        removeAccessory,
        updateMigrate,
        updateDeliveryAddress,
        updateOrderType,
        setSelectedBundle,
        updateSoftPosCreationData,
      }}
    >
      {children}
    </PosContext.Provider>
  );
};
