import { useTranslation } from "react-i18next";

export interface IStepConfig {
  number: number;
  url: string;
  name: string;
  key: string;
}

const getStepConfigs = (
  t: any
): {
  posSteps: IStepConfig[];
  migrationSteps: IStepConfig[];
  onlyAccessoriesSteps: IStepConfig[];
  tapToPaySteps: IStepConfig[];
} => {
  return {
    posSteps: [
      {
        number: 1,
        url: `/pos/terminal`,
        name: `${t("pos.navbar.terminal")}`,
        key: "Terminal",
      },
      {
        number: 2,
        url: `/pos/subscription`,
        name: `${t("pos.navbar.subscription")}`,
        key: "Subscription",
      },
      {
        number: 3,
        url: "/pos/bundle",
        name: `${t("pos.navbar.bundle")}`,
        key: "Bundle",
      },
      {
        number: 4,
        url: "/pos/accessories",
        name: `${t("pos.navbar.accessories")}`,
        key: "Accessories",
      },
      {
        number: 5,
        url: "/pos/location",
        name: `${t("pos.navbar.location")}`,
        key: "Location",
      },
    ],
    migrationSteps: [
      {
        number: 1,
        url: "/pos/migrate/location",
        name: `${t("pos.navbar.location")}`,
        key: "migratelocation",
      },
      {
        number: 2,
        url: "/pos/migrate/terminal",
        name: `${t("pos.navbar.terminal")}`,
        key: "migrateterminal",
      },
      {
        number: 3,
        url: "/pos/migrate/bundle",
        name: `${t("pos.navbar.bundle")}`,
        key: "migratebundle",
      },
      {
        number: 4,
        url: "/pos/migrate/review",
        name: `${t("pos.navbar.review")}`,
        key: "migratereview",
      },
    ],
    onlyAccessoriesSteps: [
      {
        number: 1,
        url: "/accessories-flow/accessories",
        name: `${t("pos.navbar.accessories")}`,
        key: "onlyaccessoriesview",
      },
      {
        number: 2,
        url: "/accessories-flow/review",
        name: `${t("pos.navbar.review")}`,
        key: "onlyaccessoriesreview",
      },
    ],
    tapToPaySteps: [
      {
        number: 1,
        url: "/pos/taptopay/location",
        name: t("terminals.softpos.location.stepperName"),
        key: "softposlocation",
      },
      {
        number: 2,
        url: "/pos/taptopay/configuration",
        name: t("terminals.softpos.configuration.stepperName"),
        key: "softposconfiguration",
      },
      {
        number: 3,
        url: "/pos/taptopay/confirmation",
        name: t("terminals.softpos.confirmation.stepperName"),
        key: "softposconfirmation",
      },
    ],
  };
};

export default getStepConfigs;
