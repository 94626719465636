import React, { useState, useEffect } from "react";

interface MapViewProps {
  street: string;
  houseNumber: string;
  addition?: string;
  postalCode: string;
  city: string;
  country?: string;
  apiKey?: string;
  className?: string;
  imgClassName?: string;
  zoom?: number;
}

const MapView: React.FC<MapViewProps> = ({
  street,
  houseNumber,
  addition = "",
  postalCode,
  city,
  country,
  apiKey = "AIzaSyDOxl1peXPrts7N_z1FS8y7gQeu6dD4kNY",
  className = "",
  imgClassName = "",
  zoom = 17,
}) => {
  const [mapImageUrl, setMapImageUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const address = `${street} ${houseNumber} ${addition}, ${postalCode} ${city}, ${country}`;

  useEffect(() => {
    const url = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
      address
    )}&zoom=${zoom}&size=400x270&key=${apiKey}`;

    fetch(url)
      .then((response) => {
        if (response.status === 200) {
          setMapImageUrl(url);
          setLoading(false);
        } else {
          setError(`Failed to load map: ${response.status}`);
          setLoading(false);
        }
      })
      .catch((e) => {
        setError(`Error fetching map: ${e.message}`);
        setLoading(false);
      });
  }, [address, apiKey, zoom]);

  if (loading) {
    return <div className={className}>Loading map...</div>;
  }

  if (error) {
    return <div className={className}>Error: {error}</div>;
  }

  return (
    <div
      className={`${className} flex items-center justify-center rounded-full`}
    >
      {loading && (
        <div className="animate-pulse bg-gray-300 w-full h-full"></div>
      )}
      {error && <div>Error: {error}</div>}
      {!loading && mapImageUrl && (
        <img
          src={mapImageUrl}
          alt={`Map of ${address}`}
          className={imgClassName}
        />
      )}
    </div>
  );
};

export default MapView;
