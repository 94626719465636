import React from "react";
import { MultiValue, SingleValue } from "react-select";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Dropdown from "../../ui/CustomSelect";

interface SelectCountryProps {
  onCountryChange: (country: string) => void;
}
interface DropdownOption {
  value: string;
  label: string;
}

const SelectCountry: React.FC<SelectCountryProps> = ({ onCountryChange }) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();

  const CountryOptions = [
    { value: "Netherlands", label: `${t("pos.location.country.nl")}` },
    { value: "Germany", label: `${t("pos.location.country.de")}` },
    { value: "Belgium", label: `${t("pos.location.country.bl")}` },
  ];

  const handleDropdownChange = (
    selected: SingleValue<DropdownOption> | MultiValue<DropdownOption>
  ) => {
    if (selected && !Array.isArray(selected)) {
      const singleSelected = selected as DropdownOption;
      setValue("country", singleSelected.value);
      onCountryChange(singleSelected.value);
    } else {
      setValue("country", "");
      onCountryChange("");
    }
  };

  return (
    <div className="mb-4">
      <Dropdown
        name="country"
        control={control}
        label={t("placeholders.country")}
        options={CountryOptions}
        placeholder={`${t("placeholders.country")}`}
        required
        onChange={handleDropdownChange}
      />
    </div>
  );
};

export default SelectCountry;
