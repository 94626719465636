import React, { BaseSyntheticEvent, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "./Input";
import { t } from "i18next";
import * as yup from "yup";
import { useRequest } from "../../../hooks/useRequest";
import toast from "react-hot-toast";
import { OverlayStatus } from "../../../enums/OverlayStaus";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { OnboardingFormData } from "../../forms/OnboardingForm";

const addressRequriedSchema = yup.object().shape({
  address: yup
    .string()
    .required(() => `${t("pos.location.locationForm.street")}`),
  city: yup.string().required(() => `${t("pos.location.locationForm.city")}`),
  postalCode: yup
    .string()
    .required(() => `${t("pos.location.locationForm.post")}`),
  houseNo: yup
    .string()
    .required(() => `${t("pos.location.locationForm.house")}`),
});

export type AddressRequiredType = {
  id: number;
  houseNo: string;
  address: string;
  postalCode: string;
  city: string;
};

interface AddressRequiredProps {
  setOverlayStatus: (status: OverlayStatus) => void;
  setIsFormSubmitted: (isSubmitted: boolean) => void;
  overlayStatus: OverlayStatus;
  finalPayloadState?: OnboardingFormData;
}

const AddressReqired: React.FC<AddressRequiredProps> = ({
  setOverlayStatus,
  setIsFormSubmitted,
  finalPayloadState,
}) => {
  const methods = useForm<AddressRequiredType>({
    resolver: yupResolver(addressRequriedSchema),
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { handleRequest: handleCreate } = useRequest("/v1/onboarding", "post");

  const onSubmit = async (
    { kvk, coc, firstname, ...data }: any,
    e?: BaseSyntheticEvent<Object | any | undefined>
  ) => {
    const token = executeRecaptcha && (await executeRecaptcha());
    setOverlayStatus(OverlayStatus.Loading);
    const finalPayload = {
      ...finalPayloadState,
      recaptchaToken: token,
      address: {
        ...data,
      },
    };
    handleCreate(
      finalPayload,
      (res) => {
        setOverlayStatus(OverlayStatus.Success);
        setIsFormSubmitted(true);
        toast.success(t("toast.success"));
      },
      (res) => {
        if (
          res?.response?.data?.error ===
          "The following required data was missing: companyInfo."
        ) {
          toast.error(t("errors.toastError.kvkNum"));
        } else {
          toast.error(t("toast.error"));
        }
        setOverlayStatus(OverlayStatus.Error);
        setTimeout(() => {
          setOverlayStatus(OverlayStatus.None);
        }, 1500);
      }
    );
  };

  return (
    <div className="bg-white">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="flex gap-6 flex-wrap">
            <div className="flex w-full gap-8">
              <Input
                control={methods.control}
                label={t("pos.location.placeholder.street")}
                {...register("address")}
                id="street"
                type="text"
                className="w-1/2"
                error={errors.address?.message as string | undefined}
              />
              <Input
                control={methods.control}
                label={t("pos.location.placeholder.city")}
                {...register("city")}
                id="city"
                type="text"
                className="w-1/2"
                error={errors.city?.message as string | undefined}
              />
            </div>
            <div className="flex w-full gap-8">
              <Input
                control={methods.control}
                label={t("pos.location.placeholder.post")}
                {...register("postalCode")}
                id="postalCode"
                type="text"
                className="w-1/2"
                error={errors.postalCode?.message as string | undefined}
              />
              <Input
                control={methods.control}
                label={t("pos.location.placeholder.house")}
                {...register("houseNo")}
                id="houseNo"
                type="text"
                className="w-1/2"
                error={errors.houseNo?.message as string | undefined}
              />
            </div>

            <div className="flex justify-end w-full">
              <button
                type="submit"
                className="bg-primary-500 text-black font-bold py-2 px-4 rounded hover:bg-primary-600 transition duration-300"
              >
                {t("pos.buttons.finish.save")}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddressReqired;
