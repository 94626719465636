import React from "react";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  variant?: "primary" | "secondary" | "tertiary";
  size?: "big" | "small" | "xsmall";
  icon?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  variant = "primary",
  size = "big",
  icon,
}) => {
  const baseStyles = "font-medium rounded-full transition-colors duration-300";

  const variantStyles = {
    primary:
      "bg-buckaroo-green-500 text-black hover:bg-buckaroo-blue-500 hover:text-buckaroo-green-500",
    secondary:
      "bg-buckaroo-blue-500 text-white hover:bg-buckaroo-warmGray-500 hover:text-black",
    tertiary:
      "bg-white text-buckaroo-blue-500 border border-buckaroo-blue-500 hover:bg-buckaroo-blue-500 hover:text-white",
  };

  const sizeStyles = {
    big: "px-6 py-3 text-lg font-semibold",
    small: "px-5 py-3 text-sm font-semibold",
    xsmall: "px-4 py-2 text-sm font-semibold",
  };

  return (
    <button
      onClick={onClick}
      className={`${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]} flex items-center justify-center space-x-2`}
    >
      {icon && <span>{icon}</span>}
      <span>{label}</span>
    </button>
  );
};

export default Button;
