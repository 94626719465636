import { t } from "i18next";
import * as yup from "yup";

export const locationSchema = yup.object().shape({
  locationName: yup
    .string()
    .required(() => `${t("pos.location.locationForm.name")}`)
    .transform((value) => value.trim()),
  street: yup
    .string()
    .transform((value) => value.trim())
    .required(() => `${t("pos.location.locationForm.street")}`),
  city: yup
    .string()
    .required(() => `${t("pos.location.locationForm.city")}`)
    .transform((value) => value.trim()),
  country: yup
    .string()
    .required(() => `${t("pos.location.locationForm.country")}`)
    .transform((value) => value.trim()),
  houseNumber: yup
    .string()
    .required(() => `${t("pos.location.locationForm.house")}`)
    .transform((value) => value.trim()),
  postalCode: yup
    .string()
    .required(() => `${t("pos.location.locationForm.post")}`)
    .transform((value) => value.trim()),
  addition: yup.string(),
});
