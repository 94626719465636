import React, { useContext, useState } from "react";
import { PosContext } from "../../../context/PosContext";
import { IAccessory } from "../../../interfaces/IAccessory";
import { useTranslation } from "react-i18next";
import { getLocalizedDescription } from "../utilities/getLocalizedDescription";
import { t } from "i18next";
import { formatPrice } from "../utilities/formatPrice";

interface AccessoryCardProps {
  accessory: IAccessory;
  onSelect: (selectedAccessory: IAccessory, quantity: number) => void;
}

const AccessoryCard: React.FC<AccessoryCardProps> = ({
  accessory,
  onSelect,
}) => {
  const {
    updateOrder,
    orders,
    activeOrderId,
    aggregatedAccessories,
    removeAccessory,
  } = useContext(PosContext);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const activeOrder = orders.find((order) => order.orderId === activeOrderId);

  const accessoryInAggregated = aggregatedAccessories.find(
    (acc) => acc.accessory.InvoiceCode === accessory.InvoiceCode
  );

  const initialQuantity = accessoryInAggregated?.quantity || 0;
  const [quantity, setQuantity] = useState<number>(initialQuantity);
  const [isSelected, setIsSelected] = useState<boolean>(initialQuantity > 0);

  const handleIncrement = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    onSelect(accessory, newQuantity);
  };

  const handleDecrement = () => {
    const newQuantity = Math.max(quantity - 1, 0);
    setQuantity(newQuantity);
    onSelect(accessory, newQuantity);
    if (newQuantity <= 0) {
      setIsSelected(false);
      removeAccessory(accessory.InvoiceCode);
      if (activeOrder && activeOrder.selectedAccessories) {
        const updatedAccessories = activeOrder.selectedAccessories.filter(
          (acc) => acc.accessory.InvoiceCode !== accessory.InvoiceCode
        );
        updateOrder({
          ...activeOrder,
          selectedAccessories: updatedAccessories,
        });
      }
    }
  };

  const handleSelectClick = () => {
    setIsSelected(true);
    handleIncrement();
  };

  return (
    <div
      className={`bg-white rounded-xl overflow-hidden shadow-md transition duration-300 ease-in-out 
      transform hover:-translate-y-1 cursor-pointer w-48 sm:w-52 min-h-full flex flex-col
      ${
        isSelected
          ? "bg-buckaroo-gray-300 hover:shadow-xl"
          : "bg-buckaroo-gray-50 hover:bg-buckaroo-gray-200 hover:shadow-xl"
      }`}
    >
      <div className="p-4 flex-grow flex flex-col justify-between">
        <h2 className="font-semibold text-base mb-2 text-center">
          {getLocalizedDescription(
            accessory.ProductDescriptions,
            currentLanguage
          )}
        </h2>
        <img
          src={`https://www.sepay.nl${accessory.ImageUrl}`}
          alt={getLocalizedDescription(
            accessory.ProductDescriptions,
            currentLanguage
          )}
          className="w-full h-28 object-contain"
        />
        {accessory.Fees.map((fee, index) => (
          <div key={index}>
            <p className="flex justify-center font-semibold">{`€${formatPrice(
              fee.Price
            )}`}</p>
            {fee.Descriptions && (
              <p>
                {getLocalizedDescription(fee.Descriptions, currentLanguage)}
              </p>
            )}
          </div>
        ))}
      </div>
      <div
        className={`flex items-center justify-around font-medium text-lg h-10 transition duration-300 ${
          isSelected
            ? "bg-primary-500 text-black"
            : "bg-primary-500 text-black cursor-pointer"
        }`}
      >
        {isSelected ? (
          <>
            <button
              onClick={handleDecrement}
              className="focus:outline-none bg-buckaroo-blue-500 text-buckaroo-green-500 rounded-full h-8 w-8 flex items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20 12H4"
                />
              </svg>
            </button>
            <span className="px-3 py-1 bg-white rounded mx-2">{quantity}</span>
            <button
              onClick={handleIncrement}
              className="focus:outline-none bg-buckaroo-blue-500 text-buckaroo-green-500 rounded-full h-8 w-8 flex items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </button>
          </>
        ) : (
          <button onClick={handleSelectClick} className="w-full h-full">
            {t("pos.buttons.select.name")}{" "}
          </button>
        )}
      </div>
    </div>
  );
};

export default AccessoryCard;
