import * as yup from "yup";
import { t } from "i18next";

const nameRegex = /^[a-zA-ZÀ-ÿ0-9 &.@",'-]*$/;

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const validationSchema = (isNetherlands: boolean) => {
  return yup.object().shape({
    firstname: yup
      .string()
      .max(50, `${t("errors.maxLength", { max: 50 })}`)
      .matches(nameRegex, `${t("errors.valid.firstName")}`)
      .required(`${t("errors.firstName")}`)
      .test("no-scripts", `${t("errors.valid.firstName")}`, (value) => {
        return !/<\/?script>/i.test(value || "");
      }),
    lastname: yup
      .string()
      .max(50, `${t("errors.maxLength", { max: 50 })}`)
      .matches(nameRegex, `${t("errors.valid.lastName")}`)
      .required(`${t("errors.lastName")}`)
      .test("no-scripts", `${t("errors.valid.lastName")}`, (value) => {
        return !/<\/?script>/i.test(value || "");
      }),
    ...(!isNetherlands && {
      coc: yup.object().required(`${t("errors.companySearch")}`),
    }),
    ...(!isNetherlands && {
      countryCode: yup.string().required(`${t("errors.country")}`),
    }),
    ...(isNetherlands && {
      kvk: yup.object().required(`${t("errors.companySearch")}`),
    }),
    email: yup
      .string()
      .required(`${t("errors.email")}`)
      .matches(emailRegex, `${t("errors.valid.email")}`)
      .email(`${t("errors.email")}`),
  });
};
