import React, { useContext, useState, useEffect } from "react";
import { PosContext } from "../../../context/PosContext";
import { ISubscription } from "../../../interfaces/ITerminal";
import { useTranslation } from "react-i18next";
import { getLocalizedDescription } from "../utilities/getLocalizedDescription";
import { t } from "i18next";
import { formatPrice } from "../utilities/formatPrice";

export interface LeaseProps {
  selectedLeaseOption: ISubscription | undefined;
  onSelectLeaseOption: (option: ISubscription) => void;
}

const Lease: React.FC<LeaseProps> = ({
  selectedLeaseOption,
  onSelectLeaseOption,
}) => {
  const { orders, activeOrderId } = useContext(PosContext);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const order = orders.find((o) => o.orderId === activeOrderId);

  const leaseOptions =
    order?.selectedTerminal?.Subscriptions.filter((sub) =>
      sub.Type.toLowerCase().includes("lease")
    ) || [];

  const [localSelectedLeaseOption, setLocalSelectedLeaseOption] = useState<
    ISubscription | undefined
  >(selectedLeaseOption || leaseOptions[0]);

  useEffect(() => {
    setLocalSelectedLeaseOption(selectedLeaseOption);
  }, [selectedLeaseOption]);

  const handleSelectLeaseOption = (option: ISubscription) => {
    setLocalSelectedLeaseOption(option);
    onSelectLeaseOption(option);
  };

  const recurringPrice =
    localSelectedLeaseOption?.Fees.find(
      (fee) => fee.Type === "RecurringFee"
    )?.Price.toFixed(2) || "N/A";
  const oneTimePrice =
    localSelectedLeaseOption?.Fees.find(
      (fee) => fee.Type === "OneTimeFee"
    )?.Price.toFixed(2) || "N/A";

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        {leaseOptions.map((option, index) => (
          <div
            key={index}
            className={`p-4  rounded-lg cursor-pointer shadow-md hover:shadow-lg transition duration-300 ease-in-out text-center ${
              localSelectedLeaseOption?.SubscriptionInvoiceCode ===
              option.SubscriptionInvoiceCode
                ? "bg-buckaroo-gray-200"
                : "border hover:bg-buckaroo-gray-50"
            }`}
            onClick={() => handleSelectLeaseOption(option)}
          >
            <p className="text-lg font-semibold">
              {getLocalizedDescription(
                option.SubscriptionProductNames,
                currentLanguage
              )}
            </p>
            <p className="text-sm">
              €
              {formatPrice(
                option.Fees.find((f) => f.Type === "RecurringFee")?.Price ||
                  "N/A"
              )}{" "}
              / {t("pos.review.month")}
            </p>
          </div>
        ))}
      </div>

      {selectedLeaseOption && (
        <div className="mt-4 p-4">
          <p className="font-bold text-xl mb-2 text-gray-800">
            {order && order.selectedTerminal && (
              <div>
                {getLocalizedDescription(
                  order.selectedTerminal.ProductDescriptions,
                  currentLanguage
                )}
              </div>
            )}
          </p>
          <div className="space-y-2">
            <p className="text-gray-700">
              {t("pos.purchaseTotal.fee")}
              <span className="font-semibold">
                {" "}
                €{formatPrice(recurringPrice)} / {t("pos.purchaseTotal.month")}
              </span>
            </p>
            <p className="text-gray-700">
              {" "}
              {t("pos.purchaseTotal.oneTimeFee")}{" "}
              <span className="font-semibold">
                €{formatPrice(oneTimePrice)}
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lease;
