import { useLocation } from "react-router-dom";
import { OrderType } from "../../../interfaces/IPos";

export const useDetermineOrderType = (): OrderType => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const omni = query.get("omni");

  if (location.pathname.includes("/pos/review")) {
    return { flow: "POS", type: omni === "true" ? "Omni" : "PosOnly" };
  } else if (location.pathname.includes("/pos/migrate")) {
    return { flow: "Migrate", type: omni === "true" ? "Omni" : "PosOnly" };
  } else if (location.pathname.includes("/pos/taptopay")) {
    return { flow: "TapToPay", type: omni === "true" ? "Omni" : "PosOnly" };
  } else if (location.pathname.includes("/onboarding")) {
    return { flow: "OnlineOnly", type: "OnlineOnly" };
  }

  return { flow: "OnlineOnly", type: "OnlineOnly" };
};
