import { ILocaleDescription } from "../../../interfaces/IPos";

export function getLocalizedDescription(
  descriptions: ILocaleDescription[],
  currentLanguage: string
): string {
  const description = descriptions.find((d) =>
    d.Locale.includes(currentLanguage)
  );
  return description ? description.Description : "Not available";
}
