import React, { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { PosContext } from "../../../context/PosContext";
import { LocationType } from "../../../interfaces/IPos";
import LocationModal from "./LocationModal";
import AddLocationForm from "./AddLocationForm";
import DeliveryAddressCard from "../cards/DeliveryAddressCard";

const DeliveryLocation = () => {
  const { deliveryAddress, updateDeliveryAddress, orders } =
    useContext(PosContext);

  const filteredLocations =
    orders?.flatMap((order) => (order.location ? [order.location] : [])) || [];
  const uniqueLocations: LocationType[] = Array.from(
    new Map(filteredLocations.map((loc) => [loc.id, loc])).values()
  );

  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    deliveryAddress?.id
  );
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleLocationSelect = (locationId: number) => {
    setSelectedLocationId(locationId);
    const selectedLocation = locations.find((loc) => loc.id === locationId);
    if (selectedLocation) {
      updateDeliveryAddress(selectedLocation);
    }
  };

  const handleAddLocation = (newLocation: LocationType) => {
    const newLocationWithId = {
      ...newLocation,
      id: Math.random(),
      isSelected: true,
    };

    setLocations((prevLocations) => {
      const locationExists = prevLocations.some(
        (loc) => loc.id === newLocationWithId.id
      );
      if (!locationExists) {
        return [...prevLocations, newLocationWithId];
      }
      return prevLocations;
    });

    setSelectedLocationId(newLocationWithId.id);
    updateDeliveryAddress(newLocationWithId);
    setIsModalOpen(false);
  };

  const hasValidLocationData = (location: LocationType) => {
    return (
      location.locationName ||
      location.street ||
      location.houseNumber ||
      location.postalCode ||
      location.city ||
      location.country
    );
  };

  useEffect(() => {
    let updatedLocations = uniqueLocations.filter((loc) =>
      hasValidLocationData(loc)
    );
    if (
      deliveryAddress &&
      hasValidLocationData(deliveryAddress) &&
      !updatedLocations.some((loc) => loc.id === deliveryAddress.id)
    ) {
      updatedLocations.push(deliveryAddress);
    }

    setLocations((prevLocations) => {
      const filteredPrevLocations = prevLocations.filter(
        (prevLoc) =>
          updatedLocations.some((updLoc) => updLoc.id === prevLoc.id) ||
          prevLoc.isSelected
      );

      const allValidLocations = Array.from(
        new Map(
          [...filteredPrevLocations, ...updatedLocations].map((loc) => [
            loc.id,
            loc,
          ])
        ).values()
      );

      return allValidLocations;
    });
  }, [orders, deliveryAddress]);

  return (
    <div className="container mx-auto w-full">
      <div className="flex flex-wrap gap-4">
        {locations.some(hasValidLocationData) && (
          <>
            {locations.map((location) => (
              <div key={location.id}>
                <DeliveryAddressCard
                  location={location}
                  isSelected={selectedLocationId === location.id}
                  onSelect={() => handleLocationSelect(location.id)}
                />
              </div>
            ))}
          </>
        )}
        <button
          className="flex flex-col items-center justify-center p-2 border-2 border-dashed border-gray-300 rounded-xl shadow-sm hover:border-gray-400 cursor-pointer hover:bg-gray-50 transition duration-300"
          onClick={() => setIsModalOpen(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-12 h-12 text-gray-400"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <span className="text-gray-500 font-semibold mt-2">
            {t("pos.review.deliveryAddress")}
          </span>
        </button>
      </div>
      <LocationModal
        onClose={() => setIsModalOpen(false)}
        title={`${t("pos.location.addLocation")}`}
        showCloseIcon={true}
        isOpen={isModalOpen}
      >
        <AddLocationForm
          isDeliveryAddress={true}
          onAddLocation={handleAddLocation}
        />
      </LocationModal>
    </div>
  );
};

export default DeliveryLocation;
