import React, { useContext, useState } from "react";
import { ISelectedProduct, LocationType } from "../../../interfaces/IPos";
import { ISubscription } from "../../../interfaces/ITerminal";
import { PosContext } from "../../../context/PosContext";
import Modal from "../ui/Modal";
import { getLocalizedDescription } from "../utilities/getLocalizedDescription";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { formatPrice } from "../utilities/formatPrice";

interface OrderReviewProductProps {
  orderId: number;
  product: ISelectedProduct;
  subscription?: ISubscription;
  onQuantityChange: (quantityChange: number) => void;
  location?: LocationType;
}

const OrderReviewProduct: React.FC<OrderReviewProductProps> = ({
  product,
  subscription,
  onQuantityChange,
  location,
  orderId,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { removeTerminal } = useContext(PosContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleRemoveClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmRemove = () => {
    removeTerminal(orderId);
    setIsModalOpen(false);
  };

  const handleQuantityChange = (quantityChange: number) => {
    const currentQuantity = product.quantity ?? 0;

    if (currentQuantity + quantityChange <= 0) {
      setIsModalOpen(true);
    } else {
      onQuantityChange(quantityChange);
    }
  };

  const oneTimeFee =
    subscription?.Fees.find((fee) => fee.Type === "OneTimeFee")?.Price ?? 0;
  const recurringFee =
    subscription?.Fees.find((fee) => fee.Type === "RecurringFee")?.Price ?? 0;
  const buyFee =
    subscription?.Fees.find((fee) => fee.Type === "BuyFee")?.Price ?? 0;
  const totalBuyFee = buyFee * (product?.quantity || 1);
  const contractFee = oneTimeFee;
  const totalContractFee = contractFee * (product?.quantity || 1);

  return (
    <>
      <div className="flex flex-col md:grid md:grid-cols-12 px-4 pt-4 ">
        <div className=" md:col-span-3">
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-2xl font-bold text-gray-800">
              {getLocalizedDescription(
                product.ProductDescriptions,
                currentLanguage
              )}
            </h2>
          </div>
          <div className="text-md text-gray-600">
            {getLocalizedDescription(
              subscription?.SubscriptionProductNames || [],
              currentLanguage
            )}
          </div>
          <span className="text-md text-gray-700">
            €{formatPrice(recurringFee)}/{t("pos.review.month")}
          </span>
          <div className="text-md text-gray-600 mb-2">
            {t("pos.review.location")} {location?.locationName}
          </div>
        </div>

        <div className="md:col-span-1 font-semibold mt-1 text-gray-800">
          {subscription?.Type.startsWith("lease")
            ? ""
            : `€${formatPrice(buyFee)}`}
        </div>

        <div className="md:col-span-3 flex justify-end md:justify-center items-center">
          {isModalOpen && (
            <Modal
              onClose={() => setIsModalOpen(false)}
              className="max-w-lg"
              title={`${t("pos.accessories.delete.remove")}`}>
              <p className="text-lg text-gray-800 mb-4"></p>

              <div className="flex mr-2 mb-2 justify-end space-x-3">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-900 font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-gray-300">
                  {t("pos.accessories.delete.cancelBtn")}
                </button>
                <button
                  onClick={handleConfirmRemove}
                  className="bg-red-200 hover:bg-red-300 text-red-800 hover:text-red-900 font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-red-300">
                  {t("pos.accessories.delete.removeBtn")}
                </button>
              </div>
            </Modal>
          )}

          <button
            onClick={() => handleQuantityChange(-1)}
            className={`text-gray-600 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 p-2 rounded-full transition duration-300 ease-in-out ${
              product.quantity === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={product.quantity === 0}>
            −
          </button>
          <span className="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-100 rounded-lg mx-2">
            {product.quantity || 0}
          </span>
          <button
            onClick={() => handleQuantityChange(1)}
            className="text-gray-600 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 p-2 rounded-full transition duration-300 ease-in-out">
            +
          </button>
        </div>

        <div className="md:col-span-3  flex justify-start items-center">
          <span className="text-md p-1 text-gray-700">
            €{formatPrice(recurringFee * (product?.quantity || 1))}/
            {t("pos.review.month")}
          </span>
        </div>
        <div className="md:col-span-1  flex justify-start items-center text-lg font-semibold text-gray-700 ">
          {subscription?.Type.startsWith("lease")
            ? ""
            : `€${formatPrice(totalBuyFee)}`}
        </div>

        <div className="hidden md:col-span-1 sm:flex justify-end items-center">
          <button
            onClick={handleRemoveClick}
            className="col-span-1 hover:text-red-500 active:text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex flex-col md:grid md:grid-cols-12 px-4 pb-4 ">
        <div className="text-lg font-semibold text-gray-700 col-span-3">
          {t("pos.review.fee")}
        </div>
        <div className=" text-gray-700 justify-start flex col-span-1 ">
          €{formatPrice(contractFee)}
        </div>
        <div className="col-span-6"></div>
        <div className="text-lg font-semibold text-gray-700 col-span-1 flex justify-start">
          €{formatPrice(totalContractFee)}
        </div>
        <div className="sm:hidden  flex justify-end items-center">
          <button
            onClick={handleRemoveClick}
            className="col-span-1 hover:text-red-500 active:text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderReviewProduct;
