export const debounce = (func: Function, wait: number | undefined = 700) => {
  let timeout: any;

  return function executedFunction(...args: any) {
    const later = () => {
      timeout = null;

      func(...args);
    };
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
};
