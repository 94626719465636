import { useTranslation } from "react-i18next";
import { ArrowDown } from "../../icons/ArrowDown";
import { useLocation, useNavigate } from "react-router-dom";

function Navbar() {
  const { i18n } = useTranslation();

  function useQueryParameters() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(Array.from(searchParams.entries()));
    return params;
  }
  const queryParams = useQueryParameters();
  const isPartnerYellow =
    queryParams.partner ===
    "eb653c06e00ff430289f6ae7b3f899b60f2b0e02f2f082f646ce9f2c99b212ba";

  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = e.target.value;
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set("lang", lang);
    navigate(`${location.pathname}?${urlSearchParams}`);
    i18n.changeLanguage(lang);
  };

  return (
    <nav className="bg-secondary-500 flex justify-between items-center h-[70px]">
      <div className="flex items-center w-full md:w-1/2 h-1/3">
        {isPartnerYellow && (
          <img
            src={require("../../images/AA.png")}
            alt="AA Logo"
            className="md:ml-24 -mr-3 w-[100px] h-[25px] object-contain"
          />
        )}
        <div
          className={`bg-contain bg-no-repeat ${
            !isPartnerYellow ? "xl:ml-40" : "xl:10"
          }`}
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${require("../../images/png/Buckaroo_logo_green.png")})`,
          }}
        ></div>
      </div>
      <div className="relative inline-block w-24 mr-1 xl:mr-44">
        <select
          className="appearance-none bg-inherit text-white border border-secondary-500  focus:border-white rounded-full py-1 pl-8 w-full cursor-pointer"
          value={i18n.language}
          onChange={changeLanguage}
        >
          <option
            value="de"
            style={{ backgroundColor: "#fbf8f7", color: "black" }}
          >
            DE
          </option>
          <option
            value="en"
            style={{ backgroundColor: "#fbf8f7", color: "black" }}
          >
            EN
          </option>
          <option
            value="fr"
            style={{ backgroundColor: "#fbf8f7", color: "black" }}
          >
            FR
          </option>
          <option
            value="nl"
            style={{ backgroundColor: "#fbf8f7", color: "black" }}
          >
            NL
          </option>
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <ArrowDown className={"w-5 h-5"} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
