import { IBundleTransaction } from "../interfaces/IBundle";

export const bundleTransactions: IBundleTransaction[] = [
  {
    range: 50,
    pricePerMonth: 4.5,
    transactionPrice: 0.09,
    extraTransactionPrice: 0.09,
    discount: 18,
  },
  {
    range: 150,
    pricePerMonth: 12.75,
    transactionPrice: 0.085,
    extraTransactionPrice: 0.085,
    discount: 23,
  },
  {
    range: 250,
    pricePerMonth: 20.0,
    transactionPrice: 0.08,
    extraTransactionPrice: 0.08,
    discount: 27,
  },
  {
    range: 500,
    pricePerMonth: 38.0,
    transactionPrice: 0.076,
    extraTransactionPrice: 0.076,
    discount: 31,
  },
  {
    range: 1000,
    pricePerMonth: 66.0,
    transactionPrice: 0.066,
    extraTransactionPrice: 0.066,
    discount: 40,
  },
  {
    range: 2000,
    pricePerMonth: 116.0,
    transactionPrice: 0.058,
    extraTransactionPrice: 0.058,
    discount: 47,
  },
  {
    range: 0,
    pricePerMonth: 0.11,
    transactionPrice: 0.11,
  },
];
