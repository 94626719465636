import React from "react";

const Health: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <h1 className="text-5xl text-primary-500 font-bold p-10 rounded-full shadow-xl">
        Healthy
      </h1>
    </div>
  );
};

export default Health;
