import React from "react";
import { LocationType } from "../../../interfaces/IPos";

interface DeliveryAddressCardProps {
  location: LocationType;
  isSelected?: boolean;
  onSelect?: () => void;
}

const DeliveryAddressCard: React.FC<DeliveryAddressCardProps> = ({
  location,
  isSelected,
  onSelect,
}) => {
  return (
    <div
      className={`transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 cursor-pointer ${
        isSelected
          ? "bg-primary-50 border-primary-500"
          : "bg-white border-gray-200"
      } border rounded-lg shadow-md hover:shadow-lg p-3 w-full`}
      onClick={onSelect}>
      <p className="text-gray-800 flex items-center text-sm font-medium tracking-wide leading-relaxed">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-4 h-4 mr-2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
          {location.street} {location.houseNumber}, <br />
          {location.addition}
      </p>
      <p className="text-gray-800 mb-2 flex items-center text-sm font-medium tracking-wide leading-relaxed">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-4 h-4 mr-2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          {location.postalCode}, {location.city}
      </p>

      <p
        className={`text-sm flex items-center font-medium tracking-wide leading-relaxed ${
          isSelected ? "text-primary-600" : "text-gray-500"
        }`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-4 h-4 mr-2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
            />
          </svg>
        {location.country}
      </p>
    </div>
  );
};

export default DeliveryAddressCard;
