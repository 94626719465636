import React, { useState } from "react";
import { t } from "i18next";
import { PosContext } from "../../../context/PosContext";
import Modal from "../ui/Modal";
import EditBundleCard from "../cards/EditBundleCard";
import { bundleTransactions } from "../../../data/bundleData";
import { formatDutchPrice } from "../utilities/formatDutchPrice";
import Button from "../buttons/Button";

const OrderReviewBundle: React.FC = () => {
  const { selectedBundle, setSelectedBundle } = React.useContext(PosContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSelectedBundle, setNewSelectedBundle] = useState(selectedBundle);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveBundle = () => {
    setSelectedBundle(newSelectedBundle);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="col-span-3">
        <h1 className="text-xl font-bold mb-2">
          {`${
            selectedBundle.range === 0
              ? `${t("pos.bundle.noBundle")}`
              : selectedBundle.range
          }`}{" "}
          {selectedBundle.range !== 0 &&
            ` ${t("pos.review.bundle.transactionsBundle")}`}
        </h1>
        <div className="text-gray-600 mb-4">
          <span>
            {t("pos.review.bundle.outPrice")} €{selectedBundle.transactionPrice}{" "}
          </span>{" "}
          {t("pos.review.bundle.outPrice1")}
          <p>{t("pos.review.bundle.appliesMerchants")}</p>
        </div>
      </div>
      <div className="col-span-4 flex items-center justify-start">
        <div className="flex space-x-2 hover:cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 flex self-center mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
            />
          </svg>
          <div className="flex flex-col">
            <span onClick={handleOpenModal}>
              {t("pos.review.bundle.changes")}
            </span>
            <span
              className="hover:underline hover:text-primary-600"
              onClick={handleOpenModal}
            >
              {t("pos.review.bundle.editLink")}
            </span>
            {isModalOpen && (
              <Modal onClose={handleCloseModal} className="max-w-4xl">
                <div className="bg-white rounded-md sm:p-1 mx-auto relative max-w-5xl">
                  <h2 className="text-lg sm:text-2xl mt-6 sm:mt-0 font-bold mb-2 text-center text-primary-600">
                    {t("pos.review.bundle.editBundle")}
                  </h2>
                  <div className="mb-4 text-sm sm:text-base text-center font-semibold flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mr-2"
                      onClick={handleOpenModal}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                      />
                    </svg>
                    {t("pos.review.bundle.selectBundle")}
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-8 gap-y-4 mx-10 justify-center">
                    {bundleTransactions.map((bundle, index) => (
                      <EditBundleCard
                        key={index}
                        range={bundle.range}
                        pricePerMonth={bundle.pricePerMonth}
                        transactionPrice={bundle?.transactionPrice || 0}
                        extraTransactionPrice={
                          bundle?.extraTransactionPrice || 0
                        }
                        selected={newSelectedBundle?.range === bundle.range}
                        onSelect={() => setNewSelectedBundle(bundle)}
                      />
                    ))}
                  </div>
                  <div className="absolute right-0 bottom-2 sm:mr-4 space-x-3 flex justify-end">
                    <Button
                      label={t("pos.accessories.delete.cancelBtn")}
                      onClick={handleCloseModal}
                      variant="tertiary"
                      size="small"
                    />
                    <Button
                      label={t("pos.buttons.finish.save")}
                      onClick={handleSaveBundle}
                      variant="primary"
                      size="small"
                    />
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
      {selectedBundle.range !== 0 && (
        <div className="md:col-span-3 flex justify-start items-center text-md p-1 text-gray-700">
          €{formatDutchPrice(selectedBundle.pricePerMonth)}/
          {t("pos.review.bundle.perMonth")}
        </div>
      )}
      {selectedBundle.range === 0 && (
        <div className="md:col-span-3 flex justify-start items-center text-md p-1 text-gray-700">
          €{formatDutchPrice(selectedBundle.pricePerMonth)}
        </div>
      )}
    </>
  );
};

export default OrderReviewBundle;
