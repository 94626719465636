import React from "react";
import { Outlet } from "react-router-dom";
import getStepConfigs from "../../components/pos/navbar/StepsConfig";
import { useTranslation } from "react-i18next";

const Stepper = React.lazy(() => import("../../components/pos/navbar/Stepper"));
const Basket = React.lazy(() => import("../../components/pos/ui/Basket"));

const NewTerminalFlow: React.FC = () => {
  const { t } = useTranslation();

  const { posSteps } = getStepConfigs(t);

  return (
    <>
      <Stepper stepsConfig={posSteps} />
      <Basket type="newTerminal" />
      <Outlet />
    </>
  );
};

export default NewTerminalFlow;
